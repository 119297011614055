//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

 
//@import "font-awesome";
@import "bootstrap";
 
//variable overrides
 
@import "bootstrap-select";

@import "_bootstrap-datetimepicker";

//Items required to fit the  logo to the navbar
.navbar-brand
{
    margin: 0;
    padding: 0;
}

.navbar-brand img
{
    max-height: 100%;
}

body {
	margin-bottom: 50px;
	background-color: #f5f8fa;
}
